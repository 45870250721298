import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

import { ContainerSVGT, MainFont, SubFont, BeliefsColor } from '../components/Sampler'

import remark from 'remark'
import remarkHTML from 'remark-html'
import remarkParse from 'remark-parse'

import PlayButton from '../../static/img/Play Button.png'
import PauseButton from '../../static/img/Pause Button.png'
import DownloadButton from '../../static/img/Download Button.png'
import SoundIcon from '../../static/img/Sound Icon.png'
import PassageIcon from '../../static/img/Passage Icon.png'

export const BeliefsPageTemplate = ({ 
  title, 
  backgroundimage, 
  content, 
  contentComponent 
}) => {

  const sermonAudio = [
      // The Bible, Various Passages, 10/9/22
      {
        date: '10/9/22',
        title: 'The Bible',
        passage: 'https://www.biblegateway.com/passage/?search=Romans+1%3A18-21%2C+10%3A13-15%3B+Genesis+1%3A1-7%3B+Deuteronomy+8%3A2-3%3B+Psalm+107%3A24-25%3B+Isaiah+55%3A11%3B+Deuteronomy+18%3A9-19%3B+Exodus+24%3A3-7%3B+Luke+24%3A44%3B+2+Peter+3%3A15-16&version=CSB',
        audioSource: 'https://eastvillebaptist.com/cornerstones/The%20Bible.mp3'
      },
      // The Trinity, Various Passages, 10/16/22
      {
        date: '10/16/22',
        title: 'The Trinity',
        passage: 'https://www.biblegateway.com/passage/?search=Genesis+1%3A1-26%2C+John+1%3A1-14%2C+Matthew+3%3A13-17%2C+Romans+8%3A11%2C+2+Cor+13%3A13%2C+1+Cor+12%3A3%2C+1+Cor+2%3A10-15%2C+John+14%3A21&version=CSB',
        audioSource: 'https://eastvillebaptist.com/cornerstones/The%20Trinity.mp3'
      },
      // Sin, Genesis 3, 10/23/22
      {
        date: '10/23/22',
        title: 'Sin',
        passage: 'https://www.biblegateway.com/passage/?search=Genesis+3%2C+Eph+2%3A1-12%3B+Romans+2%3A3-6%3B+&version=CSB',
        audioSource: 'https://eastvillebaptist.com/cornerstones/Sin.mp3'
      },
      // Jesus, Various Passages, 10/30/22
      {
        date: '10/30/22',
        title: 'Jesus',
        passage: 'https://www.biblegateway.com/passage/?search=1+John+2%3A22-23%2C+4%3A2%2C+5%3A5%2C+5%3A9-10%3B+Hebrews+4%3A14-16%2C+7%3A23-28%2C+9%3A11-12%3B+Isaiah+9%3A2-7%3B+Daniel+7%3A13-14%3B+Romans+5%3A12-21&version=CSB',
        audioSource: 'https://eastvillebaptist.com/cornerstones/Jesus.mp3'
      },
      // Salvation, Ephesians 1:3-14, 11/6/22
      {
        date: '11/6/22',
        title: 'Salvation',
        passage: 'https://www.biblegateway.com/passage/?search=Eph+1%3A3-14%3B+Leviticus+5%3A5-6%3B+1+Peter+2%3A24%3B+Philippians+3%3A9%3B+Romans+6%3A5-6&version=CSB',
        audioSource: 'https://eastvillebaptist.com/cornerstones/Salvation.mp3'
      },
      // The Christian Life, Various Passages, 11/13/22
      {
        date: '11/13/22',
        title: 'The Christian Life',
        passage: 'https://www.biblegateway.com/passage/?search=2+Corinthians+4%3A6-17%3B+Romans+5%3A1-5%2C+8%3A18-23%3B+John+15%3A5-8%3B+Philippians+1%3A3-11%3B+Colossians+1%3A3-7&version=CSB',
        audioSource: 'https://eastvillebaptist.com/cornerstones/The%20Christian%20Life.mp3'
      },
      // The Future, Various Passages, 11/20/22
      {
        date: '11/20/22',
        title: 'The Future',
        passage: 'https://www.biblegateway.com/passage/?search=1+Cor+15%3A12-28%3B+1+Corinthians+10%3A11%3B+Galatians+1%3A3-4%2C+4%3A4%3B+Romans+5%3A9%3B+Matthew+28%3A20%3B+Revelation+21-22%3B+John+5%3A28%2C+11%3A25%3B+Eph+2%3A6%3B+Matthew+24-25%2C+13%3A24-30%3B+2+Peter+3%3A9-13&version=CSB',
        audioSource: 'https://eastvillebaptist.com/cornerstones/The%20Future.mp3'
      },
    ]

  const PageContent = contentComponent || Content

  const [sermonArray, setSermonArray] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [rateNumber, setRateNumber] = useState(1)
  const [playBuffer, setPlayBuffer] = useState(true)
  const [audioPercent, setAudioPercent] = useState(0)
  const [progressTime, setProgressTime] = useState(0)
  const [totalTime, setTotalTime] = useState(0)
  const [subPage, setSubPage] = useState(1)  

  console.log(sermonAudio.length)

  var satitle = (sermonAudio !== null && sermonAudio.length > 0) ? sermonAudio[0].title : "Refresh the Page"
  var sadate = (sermonAudio !== null && sermonAudio.length > 0) ? sermonAudio[0].date : null
  var sapassage = (sermonAudio !== null && sermonAudio.length > 0) ? sermonAudio[0].passage : null
  var safile = (sermonAudio !== null && sermonAudio.length > 0) ? sermonAudio[0].audioSource : null

  const [selectedSermonName, setselectedSermonName] = useState(satitle)
  const [selectedSermonDate, setSelectedSermonDate] = useState(sadate)
  const [selectedSermonPassage, setSelectedSermonPassage] = useState(sapassage)
  const [selectedSermonAudioSource, setSelectedSermonAudioSource] = useState(safile)

  const audioRef = useRef()

  const volumeBar = typeof document !== 'undefined' ? document.getElementById('volumeBar') : null
  const progressBar = typeof document !== 'undefined' ? document.getElementById('progressBar') : null
  const progressBackground = typeof document !== 'undefined' ? document.getElementById('progressBackground') : null
  const mainName = typeof document !== 'undefined' ? document.getElementById('mainName') : null
  const sermonPlayer = typeof document !== 'undefined' ? document.getElementById('sermonPlayer') : null

  const WindowCheck = () => {
    checkScroll()
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', WindowCheck)
  }

  useEffect(() => {
    // Run once when component is mounted
    if (sermonAudio !== null && sermonAudio.length > 0) {
      setSermonArray(sermonAudio)
    }
  }, [])

  useEffect(() => {
    if (sermonAudio !== null && sermonAudio.length > 0) {
      // Run when selectedSermonName changes
      if (selectedSermonName) {
        audioRef.current.src = selectedSermonAudioSource
        checkScroll()
        if (!playBuffer) {
          setIsPlaying(true)
        }
      }
    }
  }, [selectedSermonName])

  useEffect(() => {
    if (sermonAudio !== null && sermonAudio.length > 0) {
      // when isPlaying changes, update audio
      if (isPlaying) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [isPlaying, selectedSermonName])


  useEffect(() => {
    if (progressBackground != null) {
      progressBackground.style.width = (progressTime / totalTime) * 100 + '%'
    }
  }, [progressTime])

  const checkScroll = () => {
    if (mainName != null && sermonPlayer != null) {
      if (mainName.className == 'Name' && mainName.className != 'scrollName') {
        mainName.style.marginRight = '0px'
        if (mainName.clientWidth > (sermonPlayer.clientWidth - 308)) {
          mainName.classList.remove('Name')
          mainName.classList.add('scrollName')
          mainName.style.marginRight = -(mainName.clientWidth / 2 - (Number(mainName.style.marginRight.substr(0, (mainName.style.marginRight.length - 2)) / 2)) - (sermonPlayer.clientWidth - 308)) + 'px'
        }
      } else if (mainName.className == 'scrollName' && mainName.className != 'Name') {
        mainName.style.marginRight = -(mainName.clientWidth / 2 - (Number(mainName.style.marginRight.substr(0, (mainName.style.marginRight.length - 2)) / 2)) - (sermonPlayer.clientWidth - 308)) + 'px'
        if (mainName.clientWidth / 2 - (Number(mainName.style.marginRight.substr(0, (mainName.style.marginRight.length - 2)) / 2)) < (sermonPlayer.clientWidth - 308)) {
          mainName.classList.remove('scrollName')
          mainName.classList.add('Name')
          mainName.style.marginRight = '0px'
        }
      }
    }
  }

  const updateMetaData = () => {
    setTotalTime(audioRef.current.duration)
  }

  const setProgressBar = () => {
    if (audioRef.current) {
      setProgressTime(Math.floor(audioRef.current.currentTime))
      setAudioPercent(audioRef.current.currentTime / audioRef.current.length)
    }
  }

  const togglePlay = () => {
    setIsPlaying(!isPlaying)
  }

  const toggleRate = () => {
    const rate = audioRef.current.playbackRate
    if (rate == 1) {
      audioRef.current.playbackRate = 1.25
      setRateNumber(1.25)
    } else if (rate == 1.25) {
      audioRef.current.playbackRate = 1.5
      setRateNumber(1.5)
    } else if (rate == 1.5) {
      audioRef.current.playbackRate = 2
      setRateNumber(2)
    } else if (rate == 2) {
      audioRef.current.playbackRate = 0.75
      setRateNumber(0.75)
    } else if (rate == 0.75) {
      audioRef.current.playbackRate = 1
      setRateNumber(1)
    }
  }

  const subPageRight = () => {
    if (subPage < (sermonArray.length / 10)) {
      setSubPage(subPage + 1)
    }
  }
  const subPageLeft = () => {
    if (subPage > 1) {
      setSubPage(subPage - 1)
    }
  }

  const toHTML = value => remark()
    .use(remarkParse)
    .use(remarkHTML)
    .processSync(value)
    .toString()

  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
            })`,
        }}
      >

        {/* Google Fonts Import */}
        <link
          href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
          rel="stylesheet"
        ></link>
        <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>


          {/* BELIEFS IMAGE HEADING */}
          <div style={{display: 'grid'}}>

            {/* Colored Style Container */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              height="310px" viewBox="0 0 1563.000000 1525.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{
                gridRow: '1 / 1',
                gridColumn: '1 / 1',
              }}>

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                fill={BeliefsColor} stroke="none">
                <path d={ContainerSVGT} />
              </g>
            </svg>

            {/* "Beliefs" Text */}
            <div style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: '3.2em',
              color: '#FFFFFF',
              fontFamily: MainFont,
              fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
              letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
            }}>
              BELIEFS
          </div>
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>

        <div className="Cornerstones">

        <div className='Divider'>~</div>
        <br></br>
        <p style={{marginBottom: '50px', marginLeft: '15px', marginRight: '15px'}}>For more teaching on the basics of the Christian faith, you can check out the "Cornerstones" sermon series from 2022 right here in the sermon player below, where Pastor Andrew teaches on the core beliefs of Jesus followers that ground our church family:</p>

        {/* MAIN SERMON PLAYER HEADER */}
        <div className='PodcastPlayer' id='sermonPlayer' onLoadCapture={checkScroll}>

          {/* INVISIBLE AUDIO FILE */}
          <audio onTimeUpdate={setProgressBar} preload="metadata" onLoadedMetadata={updateMetaData} ref={audioRef} type='audio' />

          <img />

          {/* MAIN PLAY BUTTON */}
          <div className='SermonLeftSide'>
            <button className='MainPlayButton' onClick={togglePlay}>
              <img src={isPlaying ? PauseButton : PlayButton} className='ButtonIcon' />
            </button>

            <div className='NameAndDate' style={{
              fontFamily: MainFont,
              fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
              letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
            }}>
              {/* SERMON NAME */}
              <p id='mainName' className='Name' style={{marginBottom: '6px'}}>{selectedSermonName}</p>

              {/* DATE POSTED */}
              <p className='Date' style={{paddingBottom: '0px'}}>Preached {selectedSermonDate}</p>
            </div>
          </div>

          <div className='ExtraControls'>
            {/* PASSAGE BUTTON */}
            <a className='PassageButton' href={selectedSermonPassage} target="_blank" data-title="Scripture Verses" style={{
              fontFamily: SubFont,
            }}>
              <img className='PassageIcon' style={{ right: '-60px'}} src={PassageIcon} />
            </a>

            {/* VOLUME BAR */}
            <div className='VolumeAndIcon'>
              <img className='SoundIcon' src={SoundIcon} />
              <input className='Volume' id='volumeBar' type='range' min='0' max='1' step='0.01' onInput={() => audioRef.current.volume = volumeBar.value} />
            </div>

            <div className='TimerAndRate' style={{
              fontFamily: SubFont,
            }}>
              {/* PROGRESS TIMER */}
              <p className='SermonProgressTimer'>
                {/* Current Time: */}{/*Hours*/}{Math.floor(progressTime / 3600) > 0 ? Math.floor(progressTime / 3600) + ':' : null}{/*Minutes*/}{((Math.floor((progressTime / 60) - (60 * (Math.floor(progressTime / 3600)))) < 10) && (Math.floor(progressTime / 3600) > 0)) ? 0 : null}{Math.floor((progressTime / 60) - (60 * (Math.floor(progressTime / 3600))))}:{/*Seconds*/}{Math.floor(progressTime - (60 * (Math.floor(progressTime / 60)))) < 10 ? 0 : null}{Math.floor(progressTime - (60 * (Math.floor(progressTime / 60))))}
                /
                {/* Total Time: */}{/*Hours*/}{Math.floor(totalTime / 3600) > 0 ? Math.floor(totalTime / 3600) + ':' : null}{/*Minutes*/}{((Math.floor((totalTime / 60) - (60 * (Math.floor(totalTime / 3600)))) < 10) && (Math.floor(totalTime / 3600) > 0)) ? 0 : null}{Math.floor((totalTime / 60) - (60 * (Math.floor(totalTime / 3600))))}:{/*Seconds*/}{Math.floor(totalTime - (60 * (Math.floor(totalTime / 60)))) < 10 ? 0 : null}{Math.floor(totalTime - (60 * (Math.floor(totalTime / 60))))}
              </p>

              {/* PLAYBACK RATE BUTTON */}
              <button className='RateButton' onClick={toggleRate}>
                <p className='RateNumber' style={{
                  fontFamily: SubFont,
                }}>
                  {rateNumber}x
                </p>
              </button>
            </div>
          </div>

          {/* DOWNLOAD BUTTON */}
          <a className='SermonDownloadButton' href={selectedSermonAudioSource} download={selectedSermonName}>
            <img className='DownloadIcon' src={DownloadButton} />
          </a>
          <img />

          {/* PROGRESS BAR */}
          <div className='SermonProgressHolder'>
            <input className='Progress' id='progressBar' type='range' min='0' max={totalTime} step='0.01' value={progressTime} onInput={() => { audioRef.current.currentTime = progressBar.value; progressBackground.style.width = (progressBar.value / totalTime) * 100 + '%' }} />
          </div>
          <div className='SermonProgressFill' id='progressBackground' style={{
            backgroundColor: BeliefsColor
          }} />


        </div>

        <div className='Divider'>~</div>

        {sermonArray && sermonArray.map((title, q) => {

          const isCurrentlyPlaying = isPlaying && selectedSermonName === sermonArray[q].title

          if ((q <= (subPage * 10 - 1)) && (q >= (subPage - 1) * 10)) {
            return (
              <div key={sermonArray[q].title} className='PodcastListing' style={{
                fontFamily: SubFont,
              }}>
                <button className='SubButton' style={{ display: 'inline-block' }} onClick={(selectedSermonName === sermonArray[q].title) ? togglePlay : () => { setselectedSermonName(sermonArray[q].title); setSelectedSermonDate(sermonArray[q].date); setSelectedSermonPassage(sermonArray[q].passage); setSelectedSermonAudioSource(sermonArray[q].audioSource); setPlayBuffer(false) }}>
                  <img src={isCurrentlyPlaying ? PauseButton : PlayButton} className='SubButtonIcon' />
                </button>
                {/* <p style={{ display: 'inline-block' }}>Ep. {SermonEpisodes.length - q}: {title}</p> */}
                <p className='ListingName'>{q+1}. {sermonArray[q].title}</p>
              </div>
            )
          }
        }
        )
        }

        {sermonAudio.length > 10 ? (
          <div>
            {subPage > 1 ? <button onClick={subPageLeft} className='SubPageArrow'>{"<"}</button> : <img className='InvisibleArrow' />}
            <p className='SubPageText' style={{
              fontFamily: SubFont,
            }}>
              Page {subPage}
            </p>
            {subPage < (sermonAudio.length / 10) ? <button onClick={subPageRight} className='SubPageArrow'>{">"}</button> : <img className='InvisibleArrow' />}
          </div>)
          : <div className='BottomBreak' />}

      </div>
      <br></br>
      <br></br>
      </div>
    </div>
  )
}

BeliefsPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const BeliefsPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <BeliefsPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

BeliefsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default BeliefsPage

export const BeliefsPageQuery = graphql`
  query BeliefsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
